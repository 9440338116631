import React from 'react'

import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useLocation } from 'react-router-dom'
import { object, string } from 'yup'
import * as qs from 'neoqs'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { createToken } from './requests'
import {
  isLogged as isLoggedSelector,
  updateAuthenticityToken,
} from 'kiss/session/redux'

import AuthenticateLayout from '../components/layout'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import NewPassword from 'kiss/components/formik/new-password'
import { Button, Title } from '@kisskissbankbank/kitten'
import ExternalAuth from '../external'

const KissConnect = () => {
  const t = useTranslation()

  const isLogged = useSelector(isLoggedSelector)

  const dispatch = useDispatch()

  const { search } = useLocation()
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const { redirect_to, client_token } = params

  return (
    <AuthenticateLayout>
      <Helmet title="KissConnect" />
      {isLogged ? (
        <div>Already logged in</div>
      ) : (
        <div className="k-u-align-center">
          <Title
            modifier="quaternary"
            tag="h3"
            className="k-u-margin-bottom-quadruple"
          >
            {t('authenticate.kissconnect.form.title')}
          </Title>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={object().shape({
              email: string()
                .email(t('login.form.email.error.invalid'))
                .required(t('login.form.email.error.missing')),
              password: string().required(
                t('login.form.password.error.missing'),
              ),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const response = await createToken({
                  ...values,
                  redirect_to,
                  client_token,
                })

                dispatch(updateAuthenticityToken())

                const redirectUrl = new URL(redirect_to, window.location.origin)
                redirectUrl.searchParams.set('token', response?.data?.token)

                RoutingHelper.redirect(redirectUrl.toString())
              } catch (error) {
                const errorCode = error?.response?.data?.error_code
                setErrors({
                  password: t(`authenticate.kissconnect.error.${errorCode}`),
                })
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="k-u-margin-bottom-double">
                  <Label htmlFor="email">
                    {t('authenticate.login.form.email.label')}
                  </Label>
                  <SimpleText
                    name="email"
                    normalize={(value) => value.trim()}
                    type="email"
                    autoComplete="email"
                  />
                </div>
                <div className="k-u-margin-bottom-triple">
                  <Label htmlFor="password">
                    {t('login.form.password.label')}
                  </Label>
                  <NewPassword
                    name="password"
                    iconLabel="show password"
                    hiddenIconLabel="hidden password"
                    placeholder={t('login.form.password.placeholder')}
                    aria-describedby="security-notification"
                    autoComplete="new-password"
                  />
                </div>
                <Button type="submit" modifier="helium" fit="fluid">
                  {t('authenticate.login.form.submit')}
                </Button>
              </form>
            )}
          </Formik>
          <ExternalAuth returnTo={redirect_to} otherParams={{ client_token }} />
        </div>
      )}
    </AuthenticateLayout>
  )
}

export default KissConnect
