import { HorizontalStroke, pxToRem, Text } from '@kisskissbankbank/kitten'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks/use-translation'
import FacebookButton from './facebook'
import GoogleButton from './google'

const OrWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const TextSeparator = styled(Text)`
  position: absolute;
  margin: auto;
  background-color: #ffffff;
  padding: ${pxToRem(0)} ${pxToRem(10)};
`

const ExternalAuth = ({ routeParams, returnTo, otherParams }) => {
  const t = useTranslation()
  return (
    <>
      <OrWrapper className="k-u-align-center k-u-margin-vertical-quadruple">
        <HorizontalStroke
          aria-hidden
          customSize={{ width: '100%', height: '1px' }}
          color="var(--color-grey-300)"
        />
        <TextSeparator
          size="micro"
          color="font2"
          weight="600"
          lineHeight="normal"
        >
          {t('authenticate.facebook_or_email.or')}
        </TextSeparator>
      </OrWrapper>
      <GoogleButton
        routeParams={routeParams}
        returnTo={returnTo}
        otherParams={otherParams}
        className="k-u-margin-bottom-single"
      />
      <FacebookButton
        routeParams={routeParams}
        returnTo={returnTo}
        otherParams={otherParams}
      />
    </>
  )
}

export default ExternalAuth
